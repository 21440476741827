var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ErrorModalProvider } from '../error/error.modal.service';
import { BaseService } from '../base.service';
import { BenefitCalculator } from '../benefit.calculator';
import { Log } from 'ng2-logger/browser';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../error/error.modal.service";
var log = Log.create('base-product-service');
var BaseProductsService = /** @class */ (function (_super) {
    __extends(BaseProductsService, _super);
    function BaseProductsService(httpClient, errorModalProvider) {
        var _this = _super.call(this, httpClient, errorModalProvider) || this;
        _this.httpClient = httpClient;
        _this.errorModalProvider = errorModalProvider;
        return _this;
    }
    BaseProductsService.recalculateTotals = function (productData) {
        log.info('Recalculate Totals');
        BaseProductsService.calculateEnhancementPremium(productData);
        productData.totalPremium = BenefitCalculator.calculateTotal(productData.benefits);
        productData.totalDiscount = BenefitCalculator.calculateTotalDiscount(productData.benefits);
    };
    BaseProductsService.calculateEnhancementPremium = function (productData) {
        // we do this to get the enhancement (aka PPPH) calculations done
        productData.benefits.forEach(function (benefit) {
            if (benefit.type === 'enhancement') {
                // calculate the premium
                log.info('Calculating premium for ' + benefit.code);
                benefit.premium = BenefitCalculator.calc(benefit.calcPremium, benefit, productData.benefits);
            }
        });
    };
    BaseProductsService.prototype.reprice = function (spId, code, cover, escalation) {
        var data = new RepriceVO();
        data.code = code;
        data.cover = cover;
        data.sumAssuredIncrease = escalation.cover;
        data.premiumIncrease = escalation.premium;
        return this.httpClient.post('/dl/data/sp/' + spId + '/reprice', data, this.httpOptions()).pipe(catchError(this.handleHTTPError()));
    };
    BaseProductsService.prototype.getPremiumsForEscalationOptions = function (spId, code, cover, escalationOptions) {
        var data = new PremiumsForEscalationOptionsVO();
        data.code = code;
        data.cover = cover;
        data.options = escalationOptions;
        return this.httpClient.post('/dl/data/sp/' + spId + '/escalation-premiums', data, this.httpOptions()).pipe(catchError(this.handleHTTPError()));
    };
    BaseProductsService.prototype.getProviders = function () {
        return this.httpClient.get('/dl/data/sp/product/insurance-providers', this.httpOptions()).pipe(catchError(this.handleHTTPError()));
    };
    BaseProductsService.prototype.validateDiscounts = function (spId, data) {
        var url = '/dl/data/profile/sp/' + spId + '/validate-discount';
        return this.httpClient.post(url, data, this.httpOptions()).pipe(catchError(this.handleHTTPError()));
    };
    BaseProductsService.prototype.saveDiscounts = function (spId, data) {
        var url = '/dl/data/sp/' + spId + '/product/discount';
        return this.httpClient.put(url, data, this.httpOptions()).pipe(catchError(this.handleHTTPError()));
    };
    BaseProductsService.ngInjectableDef = i0.defineInjectable({ factory: function BaseProductsService_Factory() { return new BaseProductsService(i0.inject(i1.HttpClient), i0.inject(i2.ErrorModalProvider)); }, token: BaseProductsService, providedIn: "root" });
    return BaseProductsService;
}(BaseService));
export { BaseProductsService };
var ProductServerVO = /** @class */ (function () {
    function ProductServerVO() {
        this.benefits = [];
        this.isQuote = false;
        this.sendEmail = false;
    }
    return ProductServerVO;
}());
export { ProductServerVO };
var LPFBenefitServerVO = /** @class */ (function () {
    function LPFBenefitServerVO() {
        this.addons = [];
    }
    LPFBenefitServerVO.fromProductBenefit = function (sales_process_id, product) {
        var b = new BenefitServerVO();
        b.sales_process_id = sales_process_id;
        b.id = product.id;
        b.name = product.name;
        b.type = product.type;
        b.product_code = product.code;
        b.product_master_id = product.product_master_id;
        b.benefit_amount = product.cover;
        b.premium_amount = product.premium;
        b.discount_rate = product.discountRate;
        b.escalation_factor = product.escalationFactor;
        b.cover_escalation_factor = product.coverEscalationFactor;
        b.tax_year = product.taxYear;
        b.premium_increase = product.premiumIncrease;
        b.sum_assured_increase = product.sumAssuredIncrease;
        b.pricing_version = product.pricingVersion;
        b.pricing_date = product.pricingDate;
        b.ab_modifier = product.abModifier;
        return b;
    };
    LPFBenefitServerVO.fromCanCovProductBenefit = function (sales_process_id, product) {
        var b = new BenefitServerVO();
        b.sales_process_id = sales_process_id;
        b.id = product.id;
        b.name = product.name;
        b.type = product.type;
        b.product_code = product.product;
        b.product_master_id = product.product_master_id;
        b.benefit_amount = product.benefit_amount;
        b.premium_amount = product.premium_amount;
        b.discount_rate = 0;
        return b;
    };
    return LPFBenefitServerVO;
}());
export { LPFBenefitServerVO };
var BenefitServerVO = /** @class */ (function () {
    function BenefitServerVO() {
        this.addons = [];
    }
    BenefitServerVO.fromProductBenefit = function (sales_process_id, product) {
        var b = new BenefitServerVO();
        b.sales_process_id = sales_process_id;
        b.id = product.id;
        b.name = product.name;
        b.type = product.type;
        b.product_code = product.code;
        b.product_master_id = product.product_master_id;
        b.benefit_amount = product.cover;
        b.premium_amount = product.premium;
        b.discount_rate = product.discountRate;
        b.escalation_factor = product.escalationFactor;
        b.cover_escalation_factor = product.coverEscalationFactor;
        b.tax_year = product.taxYear;
        b.premium_increase = product.premiumIncrease;
        b.sum_assured_increase = product.sumAssuredIncrease;
        b.pricing_version = product.pricingVersion;
        b.pricing_date = product.pricingDate;
        b.ab_modifier = product.abModifier;
        return b;
    };
    BenefitServerVO.fromCanCovProductBenefit = function (sales_process_id, product) {
        var b = new BenefitServerVO();
        b.sales_process_id = sales_process_id;
        b.id = product.id;
        b.name = product.name;
        b.type = product.type;
        b.product_code = product.product;
        b.product_master_id = product.product_master_id;
        b.benefit_amount = product.benefit_amount;
        b.premium_amount = product.premium_amount;
        b.discount_rate = 0;
        return b;
    };
    return BenefitServerVO;
}());
export { BenefitServerVO };
var AddonServerVO = /** @class */ (function () {
    function AddonServerVO() {
        this.type = 'addon';
    }
    AddonServerVO.fromProductBenefit = function (sales_process_id, parent, product) {
        var a = new AddonServerVO();
        a.sales_process_id = sales_process_id;
        a.id = product.id;
        a.name = product.name;
        a.product_code = parent.code;
        a.product_master_id = parent.product_master_id;
        a.addon_code = product.code;
        a.benefit_amount = product.cover;
        a.premium_amount = product.premium;
        if (a.benefit_amount === -1) {
            a.benefit_amount = parent.cover;
        }
        return a;
    };
    return AddonServerVO;
}());
export { AddonServerVO };
var DiscountServerVO = /** @class */ (function () {
    function DiscountServerVO() {
        this.discount_rates = {};
    }
    return DiscountServerVO;
}());
export { DiscountServerVO };
var DependantServerVO = /** @class */ (function () {
    function DependantServerVO() {
    }
    return DependantServerVO;
}());
export { DependantServerVO };
var RepriceVO = /** @class */ (function () {
    function RepriceVO() {
    }
    return RepriceVO;
}());
var PremiumsForEscalationOptionsVO = /** @class */ (function () {
    function PremiumsForEscalationOptionsVO() {
    }
    return PremiumsForEscalationOptionsVO;
}());
