import {Log} from 'ng2-logger/client';
import {Component, EventEmitter, Inject} from '@angular/core';
import {RsaId, rsaIdValidation, RsaIdValidationCallback} from '../rsa.id.validator';
import {BehaviorSubject} from 'rxjs';
import {isNullOrUndefined} from 'util';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {
    CoverOptionsVO,
    LifecheqSummaryService,
    ProductPricingRequestVO,
    ModalData,
    ProductMapping,
    ChangeEvent,
    ProductOptionVO,
    SimplePricingRequestVO, LPFDependantVO
} from './lifecheq-summary.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ErrorVO} from '../error/error.vo';
import {DLUtil} from '../dl.util';
import {MatSliderChange} from '@angular/material/slider';
import {DropDownValuesVO} from '../select.vo';

const log = Log.create('lifecheq-Dependant-Modal');
@Component({
    selector: 'lifecheq-dependant-modal',
    templateUrl: './lifecheq-dependant-modal.component.html'
})
export class LifecheqDependantModalComponent implements RsaIdValidationCallback {

    form: FormGroup;
    coverOptions$: BehaviorSubject<CoverOptionsVO>;
    sliderPoints: any;
    changeEvents$: EventEmitter<ChangeEvent>;
    isValid = false;
    isBusy = false;
    benefit_amount: number;
    premium_amount: number;
    product_master_id: string;
    pricing: any;
    rsaId = new RsaId();
    options: ProductOptionVO[];

    constructor(private dialogRef: MatDialogRef<LifecheqDependantModalComponent>,
                private fb: FormBuilder,
                private summaryService: LifecheqSummaryService,
                @Inject(MAT_DIALOG_DATA) public data: ModalData) {
        console.log('MODAL DATA', data);
        this.setupForm(data.main);
        if (data.main) {
            this.fetchCoverOptions(null, data.dependant.product);
        }
        if (!isNullOrUndefined(data.dependant.benefit_amount)) {
            this.benefit_amount = data.dependant.benefit_amount;
        }
        if (!isNullOrUndefined(data.dependant.premium_amount)) {
            this.premium_amount = data.dependant.premium_amount;
        }
        const productList: DropDownValuesVO[] = [];
        const count: Map<string, boolean> = this.canAdd();
        data.products.forEach((item: DropDownValuesVO) => {
            if (!this.displayList().includes(item.display)) {
                item.disabled = true;
                // item.disabled = count.get(item.display);
            } else {
                item.disabled = count.get(item.display);
                productList.push(item);
            }
        });
        data.products = productList;
    }

    setupForm(main: boolean): void {
        this.coverOptions$ = new BehaviorSubject(new CoverOptionsVO('invalid'));
        this.changeEvents$ = new EventEmitter();
        if (main) {
            this.form = this.fb.group({x: ['']});
        } else {
            this.form = this.fb.group({
                product: [this.data.dependant.product, [Validators.required]],
                first_name: [this.data.dependant.first_name, [Validators.required]],
                last_name: [this.data.dependant.last_name, [Validators.required]],
                gender: [this.data.dependant.gender, [Validators.required]],
                id_or_dob: [this.data.dependant.id_or_dob, [Validators.required, Validators.pattern('[0-9]*'), rsaIdValidation(true, this)]],
            });
            this.form.get('first_name').valueChanges.pipe(debounceTime(450)).subscribe();
            this.form.get('last_name').valueChanges.pipe(debounceTime(450)).subscribe();
            this.form.get('id_or_dob').valueChanges.pipe(debounceTime(450)).subscribe(() => {
                this.rsaId.setId(this.form.get('id_or_dob').value);
                if (!isNullOrUndefined(this.rsaId.getGender())) {
                    this.form.get('gender').setValue((this.rsaId.getGender() === 'Male' ? 'M' : 'F'));
                }

                const count: Map<string, boolean> = this.canAdd();
                this.data.products.forEach((item: DropDownValuesVO, index) => {
                    let disabled = false;

                    if (item.display === 'Child') {
                        disabled = this.ageValidation(this.rsaId.getAge(), 0, 18) || count.get(item.display);
                    } else if (item.display === 'Parents / In-Laws') {
                        disabled = this.ageValidation(this.rsaId.getAge(), 18, 85) || count.get(item.display);
                     } else if (item.display === 'Spouse') {
                        disabled = this.ageValidation(this.rsaId.getAge(), 18, 85) || count.get(item.display);
                     } else if (item.display === 'Extended Family') {
                        disabled = this.ageValidation(this.rsaId.getAge(), 0, 85) || count.get(item.display);
                    }

                    this.data.products[index].disabled = disabled;
                    // item.disabled = item.disabled || disabled;
                });
            });
            this.form.get('product').valueChanges.subscribe(() => this.productSelected());
            // Initialize an Observable for consolidated, debounced change events
            this.changeEvents$.pipe(
                debounceTime(650),
                distinctUntilChanged((a, b) => {
                    return a.rsaId.getId() === b.rsaId.getId() && a.product === b.product;
                })
            ).subscribe(
                // Update premiums for change events
                ($event) => {
                    console.log($event);
                    this.fetchCoverOptions($event.rsaId, $event.product);
                }
            );
        }
    }

    public fetchCoverOptions(rsaId: RsaId, product: string): void {
        if (!this.data.main) {
            if (isNullOrUndefined(product) || product.length === 0) {
                this.invalidateSlider('no product selected');
                return;
            }
            if (isNullOrUndefined(rsaId.getId()) || (rsaId.getId().length > 8 && !rsaId.isValid())) {
                this.invalidateSlider('id is invalid');
                return;
            }
        }
        log.info('loading cover options for ' + product);
        log.info('product: ' + product);
        console.log(this.data);

        this.isValid = false;
        this.form.disable({onlySelf: true, emitEvent: false});
        this.coverOptions$.next(new CoverOptionsVO('loading'));
        const pricingVersion = this.data.dependants[0].pricing_version;

        if (rsaId === null) {
            this.data.dependants.forEach((dependant, index) => {
                if (product === dependant.product) {
                    rsaId = new RsaId();

                    rsaId.setId(dependant.id_or_dob);
                    this.data.dependants[index].age = rsaId.getAge();
                }
            });
        }

        log.info('dependant modal rsaId: ' + rsaId.getId());

        const addonsArray = [];

        this.data.dependants.forEach((dependant: LPFDependantVO) => {
            if (dependant.product === 'LPF') {
                dependant.addons.forEach(addon => {
                    addonsArray.push(addon.addon_code);
                });
            }
        });

        console.log('PRICING_VERSION', pricingVersion);

        const req = new SimplePricingRequestVO(this.data.spId, [new ProductPricingRequestVO(product, pricingVersion)], this.data.dependants as LPFDependantVO[], rsaId.getAge(), addonsArray);

        this.summaryService.getPricing(req).subscribe( (res: CoverOptionsVO) => {
            this.updateCoverOptions(product, res);
            this.form.enable({onlySelf: true, emitEvent: false});
        },  (error: any) => {
            log.error('Error getting Dependant Info', error);
            this.form.enable({onlySelf: true, emitEvent: false});
            const coverOptions = new CoverOptionsVO('invalid');
            coverOptions.error = ErrorVO.toErrorVO(error).message;
            this.coverOptions$.next(coverOptions);
        });

    }

    updateCoverOptions(product: string, res: CoverOptionsVO) {
        if (isNullOrUndefined(res)) {
            return;
        }
        if (res.hasOwnProperty('error')) {
            const coverOptions = new CoverOptionsVO('invalid');
            coverOptions.error = res.error;
            this.isValid = false;
            this.coverOptions$.next(coverOptions);
        } else {
            res.status = 'valid';
            for (const p of res.options) {
                if (!isNullOrUndefined(this.benefit_amount) && p.cover === this.benefit_amount) {
                    this.benefit_amount = p.cover;
                    res.cover = p.cover;
                    this.premium_amount = p.premium;
                }
            }
            this.sliderPoints = res.options;
            this.product_master_id = res.product_master_id;
            this.pricing = res.pricing;
            this.isValid = false;
            this.coverOptions$.next(res);

        }

    }


    private invalidateSlider(message: string) {
        log.info(message);
        this.coverOptions$.next(new CoverOptionsVO('invalid'));
    }

    formatDisplay(value: number | null): string {
        return DLUtil.compactFormat(value, false);
    }

    productSelected(): void {
        const rsaId = new RsaId();
        rsaId.setId(this.form.get('id_or_dob').value);
        const product = this.form.get('product').value;
        this.changeEvents$.emit(new ChangeEvent(rsaId, product));
    }

    get title(): string {
        if (this.isDependant) {
            return (this.data.edit ? 'Edit' : 'Add') + ' additional life';
        }
        return 'Edit Main Member';
    }

    get saveButtonText(): string {
        return this.data.edit ? 'Save' : 'Add';
    }

    get isDependant(): boolean {
        return !this.data.main;
    }

    selectionChange(id: string) {
    }

    get leadName(): string {
        return this.data.leadName;
    }

    cancel(): void {
        this.dialogRef.close('cancel');
    }

    onIdValidationChange(rsaId: RsaId): void {
        if (!isNullOrUndefined(this.form) &&
            !isNullOrUndefined(this.form.get('product')) &&
            !isNullOrUndefined(this.form.get('product').value) &&
            !isNullOrUndefined(this.form.get('gender').value)) {
            const product = this.form.get('product').value as string;
            this.changeEvents$.emit(new ChangeEvent(rsaId, product));
        }
    }

    get productHint(): string {
        const product = this.form.get('product').value;
        if (!isNullOrUndefined(product)) {
            this.options = new ProductMapping().getProduct(this.determineProductGroup(product));
        } else {
            this.options = new ProductMapping().getProduct(this.determineProductGroup(this.data.dependants[0].product));
        }
        if (!isNullOrUndefined(product) && !isNullOrUndefined(this.options)) {
            return this.getProductDescription(product);
        }
        return null;
    }

    public getProductDescription(product: string): string {
        for (const p of this.options) {
            if (p.code === product) {
                return p.description;
            }
        }
        return null;
    }

    private determineProductGroup(product: string) {
        return product.split('_')[0];
    }

    updateCover(val: MatSliderChange): void {
        this.benefit_amount = val.value;
        this.premium_amount = this.findPremiumForCover(val.value);
        this.isValid = val.value !== 0;
        this.isValid = this.premium_amount !== 0;
        this.form.markAsDirty();
    }

    private findPremiumForCover(cover): number {
        let premium = 0;
        this.sliderPoints.forEach((opt) => {
            if (cover === opt.cover) {
                premium = opt.premium;
            }
        });
        return premium;
    }

    get disableSave(): boolean {
        return !this.isValid || this.form.pristine || this.form.invalid;
    }

    save(): void {
        console.log(this.form.value);

        this.isBusy = true;
        const data = new ModalData();
        DLUtil.copyFields(this.data, data);
        data.dependant = new LPFDependantVO();
        DLUtil.copyFields(this.data.dependant, data.dependant);
        DLUtil.copyFields(this.form.value, data.dependant);
        data.dependant.benefit_amount = this.benefit_amount;
        data.dependant.premium_amount = this.premium_amount;
        data.dependant.product_master_id = this.product_master_id;
        data.dependant.pricing = this.pricing;
        data.dependant.age = this.rsaId.getAge();
        data.dependant.premium_escalation = 0.05;
        data.dependant.benefit_escalation = 0.03;

        this.dialogRef.close(data);
    }

    private displayList(): string[] {
        return ['Child', 'Spouse', 'Extended Family', 'Parents / In-Laws'];
    }

    private canAdd(): Map<string, boolean> {
        const count = new Map<string, boolean>();
        let spouse = 0;
        let child = 0;
        let extendedFamily = 0;
        let parentInLaw = 0;

        count.set('Child', false);
        count.set('Spouse', false);
        count.set('Extended Family', false);
        count.set('Parents / In-Laws', false);

        for (const dependant of this.data.dependants) {
            switch (dependant.rel) {
                case 'Extended Family':
                    ++extendedFamily;
                    break;
                case 'Child':
                    ++child;
                    break;
                case 'Spouse':
                    ++spouse;
                    break;
                case 'Parents / In-Laws':
                    ++parentInLaw;
                    break;
                default:
                    break;
            }
        }

        if (spouse === 1) {
            count.set('Spouse', true);
        }

        if (parentInLaw === 4) {
            count.set('Parents / In-Laws', true);
        }

        return count;
    }

    private ageValidation(age: number, lower: number, upper: number): boolean {
        return !(age >= lower && age <= upper);
    }
}
