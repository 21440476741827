import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
var LifecheqSummaryService = /** @class */ (function () {
    function LifecheqSummaryService(http) {
        this.http = http;
    }
    LifecheqSummaryService.prototype.getPricing = function (request) {
        return this.http.post('/dl/data/pricing/simple-for-lpf', request, httpOptions);
    };
    LifecheqSummaryService.prototype.recalculatePricing = function (request) {
        return this.http.post('/dl/data/pricing/simple-for-lpf-reprice', request, httpOptions);
    };
    LifecheqSummaryService.ngInjectableDef = i0.defineInjectable({ factory: function LifecheqSummaryService_Factory() { return new LifecheqSummaryService(i0.inject(i1.HttpClient)); }, token: LifecheqSummaryService, providedIn: "root" });
    return LifecheqSummaryService;
}());
export { LifecheqSummaryService };
var Reprice = /** @class */ (function () {
    function Reprice() {
    }
    return Reprice;
}());
export { Reprice };
var RepriceRequestVO = /** @class */ (function () {
    function RepriceRequestVO(spId, benefits) {
        this.spId = spId;
        this.benefits = benefits;
    }
    return RepriceRequestVO;
}());
export { RepriceRequestVO };
var ProductMapping = /** @class */ (function () {
    function ProductMapping() {
        this.product = new Map();
        this.populateProducts();
    }
    ProductMapping.prototype.populateProducts = function () {
        this.product.set('LPF', [
            new ProductOptionVO('LPF_S', 'A <b>Spouse</b> Age Max: 85', 'Spouse', 1),
            new ProductOptionVO('LPF_C', 'A <b>Child</b> Age Max: 18', 'Child', 999),
            new ProductOptionVO('LPF_P', '<b>Parents / In-Laws</b> Age Max: 85', 'Parents / In-Laws', 4),
            new ProductOptionVO('LPF_E', '<b>Extended Family</b> Age Max: 85', 'Extended Family', 999)
        ]);
    };
    ProductMapping.prototype.getProduct = function (prod) {
        return this.product.get(prod);
    };
    return ProductMapping;
}());
export { ProductMapping };
var ProductPricingRequestVO = /** @class */ (function () {
    function ProductPricingRequestVO(productCode, version) {
        if (version === void 0) { version = 'DEFAULT'; }
        this.productCode = productCode;
        this.version = version;
    }
    return ProductPricingRequestVO;
}());
export { ProductPricingRequestVO };
var SimplePricingRequestVO = /** @class */ (function () {
    function SimplePricingRequestVO(spId, product, benefits, age, addons) {
        this.age = null;
        this.spId = spId;
        this.product = product;
        this.benefits = benefits;
        this.age = age;
        this.addons = addons;
    }
    return SimplePricingRequestVO;
}());
export { SimplePricingRequestVO };
var LPFDependantVO = /** @class */ (function () {
    function LPFDependantVO() {
        this.premium_escalation = 0.05;
        this.benefit_escalation = 0.03;
    }
    return LPFDependantVO;
}());
export { LPFDependantVO };
var CoverOptionsVO = /** @class */ (function () {
    function CoverOptionsVO(status) {
        this.status = status;
    }
    return CoverOptionsVO;
}());
export { CoverOptionsVO };
var ProductOptionVO = /** @class */ (function () {
    function ProductOptionVO(code, desc, rel, max) {
        this.code = code;
        this.description = desc;
        this.rel = rel;
        this.max = max;
    }
    return ProductOptionVO;
}());
export { ProductOptionVO };
var ChangeEvent = /** @class */ (function () {
    function ChangeEvent(rsaId, product) {
        this.rsaId = rsaId;
        this.product = product;
    }
    return ChangeEvent;
}());
export { ChangeEvent };
var ModalData = /** @class */ (function () {
    function ModalData() {
    }
    return ModalData;
}());
export { ModalData };
