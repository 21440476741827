import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BaseModule} from '../base.module';
import {
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatTooltipModule
} from '@angular/material';
import {TextMaskModule} from 'angular2-text-mask';
import {LifecheqBaseSummaryComponent} from './lifecheq-base-summary.component';
import {LifecheqDependantModalComponent} from './lifecheq-dependant-modal.component';
import {MatCheckboxModule} from "@angular/material/checkbox";

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        HttpClientModule,
        BaseModule,
        MatSelectModule,
        MatInputModule,
        MatButtonModule,
        MatFormFieldModule,
        MatDialogModule,
        MatListModule,
        TextMaskModule,
        MatTooltipModule,
        MatSliderModule,
        MatRadioModule,
        MatCheckboxModule,
        FormsModule
    ],
    declarations: [
        LifecheqBaseSummaryComponent,
        LifecheqDependantModalComponent
    ],
    exports: [
        LifecheqBaseSummaryComponent
    ],
    entryComponents: [
        LifecheqDependantModalComponent
    ]
})
export class LifecheqBaseSummaryModule {}
