import {Injectable} from '@angular/core';
import {Benefit, BenefitAddon, EscalationCombination} from '../benefit';
import {catchError} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ErrorModalProvider} from '../error/error.modal.service';
import {BaseService} from '../base.service';
import {BenefitCalculator} from '../benefit.calculator';
import {Log} from 'ng2-logger/browser';
import {DropDownValuesVO} from '../select.vo';
import {MLFDependantVO} from '../mlf-summary/mlf-summary.service';
import {AdditionalProductVO} from '../additional-products/additional-products.service';
import {DependantVO} from "../sf-summary/sf-summary.service";
import {LPFDependantVO} from "../lifecheq/lifecheq-summary.service";

const log = Log.create('base-product-service');

@Injectable({
    providedIn: 'root'
})
export class BaseProductsService extends BaseService {

    constructor(
        protected httpClient: HttpClient,
        protected errorModalProvider: ErrorModalProvider) {
        super(httpClient, errorModalProvider);
    }

    public static recalculateTotals(productData: ProductData): void {
        log.info('Recalculate Totals');
        BaseProductsService.calculateEnhancementPremium(productData);
        productData.totalPremium = BenefitCalculator.calculateTotal(productData.benefits);
        productData.totalDiscount = BenefitCalculator.calculateTotalDiscount(productData.benefits);
    }

    public static calculateEnhancementPremium(productData: ProductData) {
        // we do this to get the enhancement (aka PPPH) calculations done
        (productData.benefits as ProductBenefit[]).forEach(benefit => {
            if (benefit.type === 'enhancement') {
                // calculate the premium
                log.info('Calculating premium for ' + benefit.code);
                benefit.premium = BenefitCalculator.calc(benefit.calcPremium, benefit, productData.benefits as ProductBenefit[]);
            }
        });
    }

    reprice(spId: string, code: string, cover: number, escalation: any): Observable<any> {
        const data = new RepriceVO();
        data.code = code;
        data.cover = cover;
        data.sumAssuredIncrease = escalation.cover;
        data.premiumIncrease = escalation.premium;
        return this.httpClient.post('/dl/data/sp/' + spId + '/reprice', data, this.httpOptions()).pipe(
            catchError(this.handleHTTPError<any>())
        );
    }

    getPremiumsForEscalationOptions(spId: string, code: string, cover: number, escalationOptions: EscalationCombination[]) {
        const data = new PremiumsForEscalationOptionsVO();
        data.code = code;
        data.cover = cover;
        data.options = escalationOptions;
        return this.httpClient.post('/dl/data/sp/' + spId + '/escalation-premiums', data, this.httpOptions()).pipe(
            catchError(this.handleHTTPError<any>())
        );
    }

    getProviders(): Observable<any> {
        return this.httpClient.get('/dl/data/sp/product/insurance-providers', this.httpOptions()).pipe(
            catchError(this.handleHTTPError<any>())
        );
    }

    validateDiscounts(spId: string, data: DiscountServerVO): Observable<DiscountServerVO> {
        const url = '/dl/data/profile/sp/' + spId + '/validate-discount';
        return this.httpClient.post(url, data, this.httpOptions()).pipe(
            catchError(this.handleHTTPError<any>())
        );
    }

    saveDiscounts(spId: string, data: DiscountServerVO): Observable<DiscountServerVO> {
        const url = '/dl/data/sp/' + spId + '/product/discount';
        return this.httpClient.put(url, data, this.httpOptions()).pipe(
            catchError(this.handleHTTPError<any>())
        );
    }

}

export interface CanCovProductData {
    benefits: CanCovBenefitResponse[][];
    uw_declined?: boolean;
    showMarkers?: boolean;
    causeText?: string;
    ptyName?: string;
    contactEmail?: string;
    generalTelephone?: string;
    uw_status?: string;
    hiv_test_required?: boolean;
    householdIncome?: number;
    banking_details: {
        bank_name: string;
        account_number: string;
        policy_debit_day: string;
    };
    totalPremium?: number;
    totalDiscount?: number;
    pricingValidationError?: string;
    sub_product_options?: DropDownValuesVO[];
    addons?: AdditionalProductVO[];
    unavailable_reasons?: string[];
}

export interface ProductData {
    benefits: ProductBenefit[] | MLFDependantVO[] | DependantVO[] | LPFDependantVO[];
    uw_declined?: boolean;
    showMarkers?: boolean;
    causeText?: string;
    ptyName?: string;
    contactEmail?: string;
    generalTelephone?: string;
    uw_status?: string;
    hiv_test_required?: boolean;
    householdIncome?: number;
    banking_details: {
        bank_name: string;
        account_number: string;
        policy_debit_day: string;
    };
    totalPremium?: number;
    totalDiscount?: number;
    pricingValidationError?: string;
    sub_product_options?: DropDownValuesVO[];
    addons?: AdditionalProductVO[];
    unavailable_reasons?: string[];
    alllife_enabled?: boolean;
    contact_id?: string;
}

export interface CanCovOption {
    cover: number;
    premium: number;
    option: string;
    selected: boolean;
    name: string;
    description: Array<string>;
}

export interface CanCovBenefitResponse {
    id: string;
    product_master_id: string;
    benefit_status: string;
    benefit_amount: number;
    premium_amount: number;
    pricingVersion: string;
    pricingDate: string;
    name: string;
    type: string;
    rel: string;
    cover: number;
    premium: number;
    product: string;
}

export interface ProductDataCanCovResponse {
    benefitId: string;
    options: CanCovOption[];
    benefits: CanCovBenefitResponse[];
    banking_details: {
        bank_name: string;
        account_number: string;
        policy_debit_day: string;
    };
}

export interface  ProductBenefit extends Benefit {
    id: string;
    description: string;
    product_master_id: string;
    benefit_status: string;
    type: string;
    uw_type: string;
    more?: string;
    pricingVersion: string;
    pricingDate: string;
    sumAssuredIncrease: number;
    premiumIncrease: number;
    taxYear: number;
    onlyDecreaseAction: boolean;
    percentageOfTotal?: number;
    maxApplicantCover: number;
    existingCover: number;
    addons: ProductBenefitAddon[];
    previous: string;
    next: string;
    showDetail: boolean;
    specificExclusions?: ProductBenefitExclusion[];
    benefit_amount: any;
    premium_amount: any;
    product_code: string;
}

export interface ProductBenefitAddon extends BenefitAddon {
    id: string;
}

export interface ProductBenefitExclusion {
    title: string;
    wording: string;
}

export class ProductServerVO {
    id: string;
    policy_id: string;
    premium: number;
    benefits: BenefitServerVO[] = [];
    isQuote = false;
    sendEmail = false;
}

export class LPFBenefitServerVO {
    sales_process_id: string;
    id: string;
    pricing_date: string;
    name: string;
    type: string;
    product_code: string;
    product_master_id: string;
    benefit_amount: number;
    premium_amount: number;
    discount_rate: number;
    escalation_factor: number;
    cover_escalation_factor: number;
    tax_year: number;
    premium_increase: number;
    sum_assured_increase: number;
    addons: AddonServerVO[] = [];
    parent_product_benefit_id: string;
    dependant: DependantServerVO;
    pricing: any;
    ab_modifier: number;
    premium_escalation?: number;
    benefit_escalation?: number;
    payout_annuity_amount: number;
    payout_term: number;
    payout_type: string;
    pricing_version: string;
    orig_waiting_period_started;
    orig_waiting_period_served;
    orig_waiting_period;

    public static fromProductBenefit(sales_process_id: string, product: ProductBenefit): BenefitServerVO {
        const b = new BenefitServerVO();
        b.sales_process_id = sales_process_id;
        b.id = product.id;
        b.name = product.name;
        b.type = product.type;
        b.product_code = product.code;
        b.product_master_id = product.product_master_id;
        b.benefit_amount = product.cover;
        b.premium_amount = product.premium;
        b.discount_rate = product.discountRate;
        b.escalation_factor = product.escalationFactor;
        b.cover_escalation_factor = product.coverEscalationFactor;
        b.tax_year = product.taxYear;
        b.premium_increase = product.premiumIncrease;
        b.sum_assured_increase = product.sumAssuredIncrease;
        b.pricing_version = product.pricingVersion;
        b.pricing_date = product.pricingDate;
        b.ab_modifier = product.abModifier;
        return b;
    }

    public static fromCanCovProductBenefit(sales_process_id: string, product: CanCovBenefitResponse): BenefitServerVO {
        const b = new BenefitServerVO();
        b.sales_process_id = sales_process_id;
        b.id = product.id;
        b.name = product.name;
        b.type = product.type;
        b.product_code = product.product;
        b.product_master_id = product.product_master_id;
        b.benefit_amount = product.benefit_amount;
        b.premium_amount = product.premium_amount;
        b.discount_rate = 0;
        return b;
    }

}

export class BenefitServerVO {

    sales_process_id: string;
    id: string;
    pricing_date: string;
    name: string;
    type: string;
    product_code: string;
    product_master_id: string;
    benefit_amount: number;
    premium_amount: number;
    discount_rate: number;
    escalation_factor: number;
    cover_escalation_factor: number;
    tax_year: number;
    premium_increase: number;
    sum_assured_increase: number;
    pricing_version: string;
    addons: AddonServerVO[] = [];
    parent_product_benefit_id: string;
    dependant: DependantServerVO;
    pricing: any;
    ab_modifier: number;
    premium_escalation?: number;
    benefit_escalation?: number;

    public static fromProductBenefit(sales_process_id: string, product: ProductBenefit): BenefitServerVO {
        const b = new BenefitServerVO();
        b.sales_process_id = sales_process_id;
        b.id = product.id;
        b.name = product.name;
        b.type = product.type;
        b.product_code = product.code;
        b.product_master_id = product.product_master_id;
        b.benefit_amount = product.cover;
        b.premium_amount = product.premium;
        b.discount_rate = product.discountRate;
        b.escalation_factor = product.escalationFactor;
        b.cover_escalation_factor = product.coverEscalationFactor;
        b.tax_year = product.taxYear;
        b.premium_increase = product.premiumIncrease;
        b.sum_assured_increase = product.sumAssuredIncrease;
        b.pricing_version = product.pricingVersion;
        b.pricing_date = product.pricingDate;
        b.ab_modifier = product.abModifier;
        return b;
    }

    public static fromCanCovProductBenefit(sales_process_id: string, product: CanCovBenefitResponse): BenefitServerVO {
        const b = new BenefitServerVO();
        b.sales_process_id = sales_process_id;
        b.id = product.id;
        b.name = product.name;
        b.type = product.type;
        b.product_code = product.product;
        b.product_master_id = product.product_master_id;
        b.benefit_amount = product.benefit_amount;
        b.premium_amount = product.premium_amount;
        b.discount_rate = 0;
        return b;
    }

}

export class AddonServerVO {

    sales_process_id: string;
    id: string;
    name: string;
    type = 'addon';
    addon: string;
    product_code: string;
    product_master_id: string;
    addon_code: string;
    benefit_amount: number;
    premium_amount: number;
    addon_version?: string;

    public static fromProductBenefit(sales_process_id: string, parent: ProductBenefit, product: ProductBenefitAddon): AddonServerVO {
        const a = new AddonServerVO();
        a.sales_process_id = sales_process_id;
        a.id = product.id;
        a.name = product.name;
        a.product_code = parent.code;
        a.product_master_id = parent.product_master_id;
        a.addon_code = product.code;
        a.benefit_amount = product.cover;
        a.premium_amount = product.premium;
        if (a.benefit_amount === -1) {
            a.benefit_amount = parent.cover;
        }
        return a;
    }

}

export class DiscountServerVO {
    discount_rates: {
        LC?: number;
        LC_ACC?: number;
        DC?: number;
        DC_ACC?: number;
        SP90?: number;
        SP90_ACC?: number;
        CI30?: number;
        CI30_ACC?: number;
    } = {};
    provider_name: string;
}

export class DependantServerVO {
    first_name: string;
    last_name: string;
    id_or_dob: string;
    gender: string;
}

class RepriceVO {
    code: string;
    cover: number;
    sumAssuredIncrease: string;
    premiumIncrease: string;
}

class PremiumsForEscalationOptionsVO {
    code: string;
    cover: number;
    options: EscalationCombination[];
}
