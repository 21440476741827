<div class="mlf-summary">
    <div class="row">
        <div class="col s12">
            <table class="dl-table">
                <thead>
                <tr>
                    <th class="text-left">Name</th>
                    <th class="text-right hide-on-small-and-down">Cover Amount</th>
                    <th class="text-right">Premium</th>
                    <th></th>
                </tr>
                </thead>
                <ng-container *ngFor="let dependant of dependants; let idx = index">
                    <tr>
                        <td class="text-left">
                            <span class="dl-badge" [matTooltip]="getHint(dependant)" [matTooltipPosition]="'after'">{{getBadge(dependant)}}</span>
                            {{dependant.name}}
                            <span class="hide-on-med-and-up"><br>{{dependant.benefit_amount | dl_currency:true:0}}</span>
                        </td>
                        <td class="text-right hide-on-small-and-down">{{dependant.benefit_amount | dl_currency:true:0}}</td>
                        <td class="text-right">{{dependant.premium_amount | dl_currency:true:2}}</td>
                        <td class="text-right">
                            <i (click)="clickEdit(dependant, idx === 0)" class="fa fa-pencil"></i>
                            <span *ngIf="idx === 0" class="hide-on-small-and-down spacer"></span>
                            <span *ngIf="idx !== 0" class="hide-on-med-and-up"><br></span>
                            <i *ngIf="idx !== 0" (click)="clickRemove(dependant)" class="fa fa-times"></i>
                        </td>
                    </tr>
                </ng-container>
                <tfoot>
                <tr>
                    <td><b>Total</b></td>
                    <td class="hide-on-small-and-down"></td>
                    <td class="text-right"><b>{{totalPremium | dl_currency:true:2}}</b></td>
                    <td></td>
                </tr>
                <tfoot>
            </table>
        </div>
    </div>

    <p>Addons</p>
    <div class="col s4" *ngFor="let addon of addonsList">
        <mat-checkbox
                [id]="addon.id"
                [(ngModel)]="addon.checked"
                [disabled]="addon.disabled"
                [value]="addon.code"
                (change)="onChangeAddon($event)">
            {{addon.name.split('-')[1]}}
        </mat-checkbox>
    </div>

    <div *ngIf="errors$ | async as errors">
        <small class="col s12 red-text" style="font-weight: bold; padding-top: 1rem;"
               *ngFor="let error of errors">{{error}}</small>
    </div>

    <div *ngIf="monthlySupportSelected">
        <div class="col s12 m6">
            <p>Type</p>
            <mat-radio-group (change)="onMonthlySupportOptionChange($event, 'payoutType')" [(ngModel)]="payoutType"
                             class="dl-full-width">
                <mat-radio-button *ngFor="let payoutType of payoutTypeList"
                        [tabIndex]="1"
                        [value]="payoutType.value"
                        class="mat-button-toggle-2"
                        color="primary">{{ payoutType.name }}
                </mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="col s12 m6" data-comp-id="dependant.payout_type">
            <p>Term</p>
            <mat-radio-group (change)="onMonthlySupportOptionChange($event, 'payoutTerm')" [(ngModel)]="payoutTerm"
                             class="dl-full-width">
                <mat-radio-button *ngFor="let payoutTerm of payoutTermList"
                        [value]="payoutTerm.value"
                        class="mat-button-toggle-2"
                        color="primary">{{ payoutTerm.name }}
                </mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="col s12 m6" data-comp-id="dependant.payout_term">
            <p class="pt-0 mt-0">Amount</p>
            <mat-radio-group (change)="onMonthlySupportOptionChange($event, 'payoutAnnuityAmount')"
                             [(ngModel)]="payoutAnnuityAmount" class="dl-full-width">
                <mat-radio-button *ngFor="let payoutAnnuityAmount of payoutAnnuityAmountList"
                        [value]="payoutAnnuityAmount.value"
                        class="mat-button-toggle-2"
                        color="primary">{{ payoutAnnuityAmount.name }}
                </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>

    <div class="col s12">
        <p class="text-center">Do you want to add additional lives to this policy?</p>
    </div>

    <div class="col s8 offset-s2 m6 offset-m3 l4 offset-l4">
        <button class="dl-full-width"
                mat-raised-button
                tabindex="1"
                [attr.data-name]="'add'"
                [attr.data-event]="'true'"
                [attr.data-event-category]="eventCategory"
                [attr.data-event-name]="'add-edit dependant'"
                [attr.data-event-label]="'add-edit dependant'"
                (click)="clickAdd()">Add</button>
    </div>
</div>
