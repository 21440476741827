import {Dynamic} from './component';
import {Component} from '@angular/core';
import {FormControl} from '@angular/forms';
import {PageComponentVO, ProductBenefitVO} from '../page.data.vo';
import {DspService} from '../dsp.service';
import {MatSliderChange} from '@angular/material';
import {DLUtil} from '../../base/dl.util';

@Component({
    templateUrl: 'lifecheq-main.component.html',
    selector: 'lifecheq-main',
    styleUrls: ['./info-card.component.scss']
})
export class LifecheqMainComponent extends Dynamic<PageComponentVO>  {

    constructor(private dspService: DspService) {
        super();
    }

    setupFromControl() {
        this.component.loaded = true;
        // tel my parent I'm loaded.
        this.loaded.emit(this.component.id);
    }

    getFormControl(): FormControl {
        return undefined;
    }

    get val(): ProductBenefitVO {
        return this.component.value as ProductBenefitVO;
    }

    get name(): string {
        return this.dspService.currentData.leadName;
    }

    get minCover(): number {
        return this.val.options[0][0].benefit_amount;
    }

    get maxCover(): number {
        return this.val.options[0][this.val.options[0].length - 1].benefit_amount;
    }

    get increments(): number {
        if (this.val.options[0].length > 1) {
            return (this.val.options[0][1].benefit_amount - this.val.options[0][0].benefit_amount);
        }
        return 5000;
    }

    formatDisplay(value: number | null): string {
        return DLUtil.compactFormat(value, false);
    }

    updateCover(val: MatSliderChange): void {
        this.val.options[0].forEach((opt) => {
            if (val.value === opt.benefit_amount) {
                this.val.benefit_amount = opt.benefit_amount;
                this.val.premium_amount = opt.premium_amount;
            }
        });
    }

}
