<mat-card>
    <mat-card-content>
        <div *ngIf="isBusy" class="dl-loading absolute">
            <p>
                <span class="fa fa-circle-o-notch fa-spin"></span>
            </p>
        </div>
        <lifecheq-base-summary
            [spId]="spId"
            [benefitId]="benefitId"
            [leadName]="leadName"
            [products]="products"
            [dependants]="dependants"
            [eventCategory]="'DSP-' + productCode"
            (add)="add($event)"
            (edit)="edit($event.main, $event.dependant)"
            (remove)="remove($event)"></lifecheq-base-summary>
    </mat-card-content>
</mat-card>
